import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { AuthProvider } from 'components/AuthProvider'
import { Layout, AuthLayout, SessionLayout } from 'components/Layout'
import { ForgotPassword, SignIn, AuthCallback, Maintenance } from 'pages/public'
import {
  Dashboard,
  TodayAppointments,
  Appointments,
  Users,
  Flowsheet,
  Clinics,
  Pumps,
  Patients,
  SelectClinicScreen
} from 'pages/private'
import { Toaster } from 'react-hot-toast'
import { SessionProvider } from 'components/SessionProvider'
import { PatientFormScreen } from './private/Patients/PatientForm'
import { AppointmentFormScreen } from './private/Appointments/AppointmentForm'
import { LocalEMR } from 'components/LocalEMR'
import { FieldsManagement } from './private/Fields'
import { useAppVersion } from 'hooks/useAppVersion'
import { NoActiveUserScreen } from './public/NoActiveUser'
import { ClinicProtectedRoute } from 'components/ClinicProtectedRoute'

export const Pages = () => {
  useAppVersion()

  return (
    <AuthProvider>
      <Toaster />
      <Routes>
        <Route element={<Layout />}>
          <Route path='/login' element={<SignIn />} />
          <Route path='/auth-callback' element={<AuthCallback />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/select-clinic' element={<SelectClinicScreen />} />
          <Route path='/user-no-active' element={<NoActiveUserScreen />} />
          <Route path='/maintenance' element={<Maintenance />} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path='/' element={<Dashboard />} />

          {/* Admin routes */}
          <Route path='/users' element={<Users />} />
          <Route path='/fields-management' element={<FieldsManagement />} />
          <Route path='/clinics' element={<Clinics />} />

          {/* App routes */}
          <Route path='/clinics/:clinicId' element={<ClinicProtectedRoute />}>
            <Route path='today-appointments' element={<TodayAppointments />} />
            <Route path='pumps' element={<Pumps />} />
            <Route path='appointments' element={<Appointments />} />
            <Route path='patients' element={<Patients />} />
            <Route path='patients/new' element={<LocalEMR><PatientFormScreen isNewPatient /></LocalEMR>} />
            <Route path='patients/:id' element={<PatientFormScreen />} />
            <Route path='appointments/:id' element={<AppointmentFormScreen />} />
            <Route
              path='sessions/:id'
              element={
                <SessionProvider>
                  <Flowsheet />
                </SessionProvider>
              }
            />
          </Route>
        </Route>

        {/* PDF-render shortcut route */}
        <Route element={<SessionLayout />}>
          <Route
            path='/sessions/:id'
            element={
              <SessionProvider>
                <Flowsheet />
              </SessionProvider>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  )
}

export const Providers = () => {
  return (
    <AuthProvider>
      <Toaster />
      <Pages />
    </AuthProvider>
  )
}

import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { Navbar } from 'components/Navbar'
import { useBoot } from 'hooks'

export const Layout = () => {
  return (
    <div className='container m-auto max-w-screen-2xl flex flex-col justify-start items-start px-8 py-10 h-screen'>
      <Outlet />
    </div>
  )
}

export const AuthLayout = () => {
  const { isBooting } = useBoot()
  const auth = useAuth()
  const location = useLocation()

  if (!auth?.token) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return (
    <div className='h-screen'>
      <Navbar />
      <div className='flex-grow container mx-auto max-w-screen-2xl px-8 py-10 hide-scrollbar print:px-0'>
        {isBooting ? <span className='loading loading-spinner loading-sm' /> : <Outlet />}
      </div>
    </div>
  )
}

export const SessionLayout = () => {
  useBoot()

  return (
    <>
      <Outlet />
    </>
  )
}
import React from 'react'
import { InfusionSession } from 'types'
import { PumpingSession } from 'components/PumpingSession'
import { InfusionSessionHourReading } from 'components/InfusionSessionHourReading'
import { HR1Section } from 'components/HR1Section'
import BlurredWrapper from 'components/BlurredWrapper'
import { useSessionContext } from 'components/SessionProvider'
import { useCheckSessionStartedFields } from 'lib/session'
import clsx from 'clsx'

interface InfusionSessionHourProps {
  data: InfusionSession
  hour: number
  onChange: (data: Partial<InfusionSession>) => void
  deleteInfusionInterval: (hour: number, interval: number) => Promise<boolean>
  canPump: boolean
}

export default function InfusionSessionHour({ data, hour, onChange, canPump, deleteInfusionInterval }: InfusionSessionHourProps) {
  const { isSessionStarted } = useSessionContext()
  const areSessionStartedFieldsValid = useCheckSessionStartedFields(data)
  const isSessionOkToContinue = isSessionStarted() && areSessionStartedFieldsValid && canPump

  return (
    <div className='mt-8'>
      {hour === 0 ? (
        <>
          <BlurredWrapper allow={isSessionStarted()}>
            <HR1Section data={data} onChange={onChange} />
          </BlurredWrapper>
          <BlurredWrapper allow={isSessionOkToContinue}>
            <div className={clsx(`flex flex-row bg-gray-100 p-2 justify-center opacity-100`, {
              'shadow-md': !canPump
            })}>
              <span className='text-dark-gray font-bold'>{'Start Pump if BGL >= 120'}</span>
            </div>
            <PumpingSession
              canPump={canPump}
              data={data}
              onChange={onChange}
              hour={0}
              deleteInfusionInterval={deleteInfusionInterval}
            />
          </BlurredWrapper>
        </>
      ) : (
          <BlurredWrapper allow={isSessionOkToContinue}>
            <InfusionSessionHourReading data={data} hour={hour} onChange={onChange} />
            <PumpingSession
              data={data}
              hour={hour}
              onChange={onChange}
              canPump={true}
              deleteInfusionInterval={deleteInfusionInterval}
            />
          </BlurredWrapper>
      )}
    </div>
  )
}